import Router from 'next/router';
import { clientTypesMap } from 'src/enums/clientsTypes';

const createRedirectTo = (ctx) => (data) => {
  if (!data && !data?.url) {
    return;
  }
  const { layout, url } = data;
  if (ctx?.asPath.replace(/\//g, '') === url.replace(/\//g, '')) {
    return;
  }

  if (typeof window !== 'undefined') {
    Router.push(`/${layout}`, url);
  } else {
    ctx.res.writeHead(302, { Location: `/${url}` }).end();
  }
};

export const redirectUserBasedOnUserTypes = (redirects, ctx) => {
  const redirectTo = createRedirectTo(ctx);
  const { user } = ctx;

  redirectTo(!user ? redirects.default : redirects[clientTypesMap.get(user?.clientType)]);
};
