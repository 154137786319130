import { datoCMSQuery } from "./apolloUtils";

import * as articlesTypes from 'config/articlesTypes';

import GET_ARTICLES_COUNT from 'src/graphql/queries/getArticlesCount.gql';
import GET_ARCHIVED_ARTICLES_COUNT from 'src/graphql/queries/getArticlesArchivedCount.gql';
import GET_ACTIVE_ARTICLES_COUNT from 'src/graphql/queries/getArticlesActiveCount.gql';
import GET_ARTICLE_CATEGORIES_DETAILS from 'src/graphql/queries/getArticleCategoriesDetails.gql';
import GET_SELECTED_ACTIVE_ARTICLES from 'src/graphql/queries/getArticlesActiveSelected.gql';
import GET_SELECTED_ARTICLES from 'src/graphql/queries/getArticlesSelected.gql';
import GET_SELECTED_ARCHIVED_ARTICLES from 'src/graphql/queries/getArticlesArchivedSelected.gql';

import { mapDatoResponseToArticleCategoriesArray } from "./mapDatoResponseToArticleCategoriesArray";
import { mapDatoResponseToArticleTagsArray } from "./mapDatoResponseToArticleTagsArray";
import { NOT_LOGGED_IN_TAG_ID } from 'src/../config/personalizationTags';


const articlesCountFetchQueries = {
  [articlesTypes.ACTIVE]: GET_ACTIVE_ARTICLES_COUNT,
  [articlesTypes.ARCHIVED]: GET_ARCHIVED_ARTICLES_COUNT,
  [articlesTypes.ALL]: GET_ARTICLES_COUNT,
};

const articlesFetchQueries = {
  [articlesTypes.ACTIVE]: GET_SELECTED_ACTIVE_ARTICLES,
  [articlesTypes.ARCHIVED]: GET_SELECTED_ARCHIVED_ARTICLES,
  [articlesTypes.ALL]: GET_SELECTED_ARTICLES,
};

const articlesDatoModelKeys = {
  ARTICLES_LIST_ASIDE: 'articles_list_aside',
  ARTICLES_LIST: 'articles_list',
  ARTICLES_CAROUSEL: 'articles_carousel'
}

const userPersonalizationTags = (isPersonalizedEnabled, user) => {
  const isLoggedIn = !!user;

  if(!isPersonalizedEnabled) {
    return undefined;
  }

  if(isLoggedIn) {
    return user?.tags?.filter((tag) => !Number.isNaN(Number(tag))) || [];
  }

  return [NOT_LOGGED_IN_TAG_ID];
}

const getArticlesCount = (articleData, locale, apolloClient) => {
  const queryCount = articlesCountFetchQueries[articleData.articlesType]
  const categoriesArray = articleData.categories ? 
    mapDatoResponseToArticleCategoriesArray(articleData.categories).join('|') : [];
  const tagsArray = articleData.tags ? mapDatoResponseToArticleTagsArray(articleData.tags) : [];
  const date = new Date();

  const articlesCount = datoCMSQuery({
    query: queryCount,
    client: apolloClient,
    variables: { 
      locale: locale,
      categories: categoriesArray,
      tags: tagsArray,
      archiveStartDate: date.toISOString().split('T')[0],
    },
  }).catch((err) => {
    console.debug(err);
  });

  if (articlesCount) {
    return articlesCount
  } else {
    return null;
  }
}

const getArticlesCountByPersonalizationTags = (articleData, locale, apolloClient, user) => {
  const queryCount = articlesCountFetchQueries[articleData.articlesType]
  const categoriesArray = articleData.categories ? 
    mapDatoResponseToArticleCategoriesArray(articleData.categories).join('|') : [];
  const tagsArray = articleData.tags ? mapDatoResponseToArticleTagsArray(articleData.tags) : [];
  const date = new Date();
  const personalizationTags = userPersonalizationTags(articleData.isPersonalizationEnabled, user)

  const articlesCount = datoCMSQuery({
    query: queryCount,
    client: apolloClient,
    variables: { 
      locale: locale,
      categories: categoriesArray,
      tags: tagsArray,
      archiveStartDate: date.toISOString().split('T')[0],
      sortingByPersonalizationTags: articleData.sorting,
      personalizationTags: !articleData.isPersonalizationEnabled ? undefined : personalizationTags
    },
  }).catch((err) => {
    console.debug(err);
  });

  if (articlesCount) {
    return articlesCount
  } else {
    return null;
  }
}

const getAllArticles = ({articleData, locale, apolloClient, user, clearImage, thumbWidth}) => {
  const queryCount = !!articleData.articlesType ? 
    articlesFetchQueries[articleData.articlesType] : GET_SELECTED_ACTIVE_ARTICLES;
  const categoriesArray = articleData.categories ? 
    mapDatoResponseToArticleCategoriesArray(articleData.categories).join('|') : [];
  const tagsArray = articleData.tags ? mapDatoResponseToArticleTagsArray(articleData.tags) : [];
  const date = new Date();
  const personalizationTags = userPersonalizationTags(articleData.isPersonalizationEnabled, user)

  const allArticles = datoCMSQuery({
    query: queryCount,
    client: apolloClient,
    variables: { 
      locale: locale,
      categories: categoriesArray,
      tags: tagsArray,
      archiveStartDate: date.toISOString().split('T')[0],
      skip: articleData.skip > 0 ? articleData.skip : 0,
      take: articleData.take,
      sortingByPersonalizationTags: undefined,
      personalizationTags: !articleData.isPersonalizationEnabled ? undefined : personalizationTags,
      thumbWidth: !!clearImage ? undefined : thumbWidth || 480,
      ar: !!clearImage ? undefined : '16:9',
      fit: !!clearImage ? undefined : 'crop',
    },
  }).catch((err) => {
    console.debug(err);
  });

  if (allArticles) {
    return allArticles;
  } else {
    return [];
  }
}

const getAllArticlesByPersonalizationTags = ({
  articleData, 
  locale, 
  apolloClient,
  user, 
  clearImage, 
  thumbWidth, 
  take, 
  skip,
  sortingByPersonalizationTags
}) => {
  const queryCount = !!articleData.articlesType ? 
    articlesFetchQueries[articleData.articlesType] : GET_SELECTED_ACTIVE_ARTICLES;
  const categoriesArray = articleData.categories ? 
    mapDatoResponseToArticleCategoriesArray(articleData.categories).join('|') : [];
  const tagsArray = articleData.tags ? mapDatoResponseToArticleTagsArray(articleData.tags) : [];
  const date = new Date();
  const personalizationTags = userPersonalizationTags(articleData.isPersonalizationEnabled, user)

  const allArticles = datoCMSQuery({
    query: queryCount,
    client: apolloClient,
    variables: { 
      take: take,
      skip: skip > 0 ? skip : 0,
      categories: categoriesArray,
      tags: tagsArray || [],
      personalizationTags: personalizationTags,
      // NOTE: Using `null` instead `undefined` leads to adding empty params values,
      // such as: `/...schneider-electric.jpg?ar=&fit=&w=`
      thumbWidth: !!clearImage ? undefined : thumbWidth || 480,
      ar: !!clearImage ? undefined : '16:9',
      fit: !!clearImage ? undefined : 'crop',
      locale: locale || defaultLanguage,
      archiveStartDate: date.toISOString().split('T')[0],
      sortingByPersonalizationTags,
    },
  }).catch((err) => {
    console.debug(err);
  });

  if (allArticles) {
    return allArticles;
  } else {
    return [];
  }
}

const getAllArticlesCategories = (articleComponent, locale, apolloClient) => {

  const categories = articleComponent.categories ? 
    mapDatoResponseToArticleCategoriesArray(articleComponent.categories).join('|') : [];

  const allCategoriesResponse = datoCMSQuery({
    query: GET_ARTICLE_CATEGORIES_DETAILS,
    client: apolloClient,
    variables: { 
      locale: locale,
      titles: categories,
    },
  }).catch((err) => {
    console.debug(err);
  });

  if(allCategoriesResponse) {
    return allCategoriesResponse
  } else {
    return [];
  }
}

export const getArticles = async (data, locale, user, apolloClient) => {
  const updatedData = [...data]

  const updatedDataResponse = await Promise.all(updatedData.map(async (datoComponent) => {
    if (datoComponent._modelApiKey === articlesDatoModelKeys.ARTICLES_CAROUSEL 
      || datoComponent._modelApiKey === articlesDatoModelKeys.ARTICLES_LIST
      || datoComponent._modelApiKey === articlesDatoModelKeys.ARTICLES_LIST_ASIDE) {
      let initialArticles = [];
      let thumbWidth;
      let clearImage;

      if(datoComponent._modelApiKey === articlesDatoModelKeys.ARTICLES_CAROUSEL) {
        const isSinglePerPage = datoComponent.slidesPerPage === 1;
        thumbWidth = isSinglePerPage ? 960 : 640;
        clearImage = isSinglePerPage;
      }
      
      const articlesCountResponse = await getArticlesCount(datoComponent, locale, apolloClient);
      const articlesCount = articlesCountResponse?.data?._allArticlesMeta?.count;
      const articlesAllCategoriesResponse = await getAllArticlesCategories(datoComponent, locale, apolloClient);
      const articlesAllCategories = articlesAllCategoriesResponse?.data?.allArticleCategories

      if(datoComponent.sorting) {
        const articlesWithPersonalizationCountResponse = 
          await getArticlesCountByPersonalizationTags(datoComponent, locale, apolloClient, user);
        const articlesWithPersonalizationCount = articlesWithPersonalizationCountResponse?.data?._allArticlesMeta?.count

        if (articlesCount < 0 || articlesWithPersonalizationCount < 0) {
          return {
            ...datoComponent,
            initialArticles: []
          }
        }

        const pageIdx = 0;
        const skip = pageIdx * datoComponent.take;
        const skipWithoutTag = Math.max(skip - articlesWithPersonalizationCount, 0);
        const articlesWithoutTagsCount = articlesCount - articlesWithPersonalizationCount;

        const numberWithTagToFetch = Math.min(datoComponent.take, articlesWithPersonalizationCount - skip);
        const numberWithoutTagToFetch = Math.min(
          datoComponent.take - Math.max(0, numberWithTagToFetch),
          articlesWithoutTagsCount - skipWithoutTag,
        );

        const articlesWithNumberWithTagToFetchResponse = numberWithTagToFetch <= 0 
        ? [] : await getAllArticlesByPersonalizationTags({
          articleData: datoComponent, 
          locale, 
          apolloClient,
          user,
          take: numberWithTagToFetch, 
          skip,
          sortingByPersonalizationTags: datoComponent.sorting,
          thumbWidth,
          clearImage
        })
        const articlesWithNumberWithoutTagToFetchResponse = numberWithoutTagToFetch <= 0 
          ? [] : await getAllArticlesByPersonalizationTags({
          articleData: datoComponent, 
          locale, 
          apolloClient,
          user,  
          take: numberWithoutTagToFetch, 
          skip,
          sortingByPersonalizationTags: false,
          thumbWidth,
          clearImage
        })

        const articlesWithNumberWithTagToFetch = articlesWithNumberWithTagToFetchResponse?.data?.allArticles
        const articlesWithNumberWithoutTagToFetch = articlesWithNumberWithoutTagToFetchResponse?.data?.allArticles

        if(articlesWithNumberWithTagToFetch && articlesWithNumberWithoutTagToFetch) {
          initialArticles = [
            ...articlesWithNumberWithTagToFetch,
            ...articlesWithNumberWithoutTagToFetch
          ]
        }
      } else {
        if(!articlesCount) return {
          ...datoComponent,
          initialArticles: []
        };
        
        const initialArticlesResponse = await getAllArticles({
          articleData: datoComponent, 
          locale, 
          apolloClient, 
          user, 
          thumbWidth, 
          clearImage
        });

        if(initialArticlesResponse) {
          initialArticles = initialArticlesResponse.data?.allArticles;
        }
      }

      const hasArticlesAndCategoriesData = initialArticles && articlesAllCategories;

      if(!hasArticlesAndCategoriesData) {
        return {
          ...datoComponent,
          initialArticles: []
        }
      }

      const categoriesSlugsDict = articlesAllCategories.reduce((slugsDict, { title, page }) => {
        const dict = { ...slugsDict };
    
        if (!dict[title]) {
          dict[title] = {
            slug: page.slug,
            layout: page.layout,
          };
        }
    
        return dict;
      }, {});

      const filterInitialArticles = initialArticles
        ?.filter((article) => !!categoriesSlugsDict[article.category])
        ?.map((article) => {
          const { layout, slug } = categoriesSlugsDict[article.category];
    
          return {
            ...article,
            layout,
            slug: `${slug}/${article.slug}`,
          };
        });

      return {
        ...datoComponent,
        initialArticles: filterInitialArticles,
      } 
    } else {
      return datoComponent;
    }
  }));

  return updatedDataResponse;
}