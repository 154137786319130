import { prepareRoutingMaps, getBreadcrumbsHierarchy } from 'setup/nextRoutes';
import { defaultLanguage } from 'config/locales';

export const getBreadcrumbs = async ({ isSSR, req, locale, slug, routingMap: cachedRoutingMap } = {}) => {
  let breadcrumbs = null;
  if (cachedRoutingMap) {
    const slugForPageId = locale === defaultLanguage ? slug : `${locale}/${slug}`;
    const currentPageId = cachedRoutingMap.slugsMap.get(slugForPageId);

    breadcrumbs = getBreadcrumbsHierarchy(locale, cachedRoutingMap.routesMap, currentPageId);
  } else if (isSSR) {
    breadcrumbs = req.breadcrumbs;
  } else {
    const routingMap = await prepareRoutingMaps([locale], process.env.cmsURL, process.env.cmsReadOnlyToken);
    const currentPageId = routingMap.slugsMap.get(slug);

    breadcrumbs = getBreadcrumbsHierarchy(locale, routingMap.routesMap, currentPageId);
  }

  return breadcrumbs;
};
