import GET_PAGE_CONTENT_BY_SLUG from 'src/graphql/queries/getPageContentBySlug.gql';
import { getSlugFromUrl } from 'setup/nextRoutes';
import { defaultLanguage } from 'config/locales';
import { getCachedRoutingMap } from 'src/utils/getCachedRoutingMap';
import { getBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { getCoreRoutesInfoByLocale } from 'src/utils/getCoreRoutesInfoByLocale';
import { redirectUserBasedOnUserTypes } from 'src/utils/redirectUserBasedOnUserTypes';
import {
  createBasicPageWithMainLayoutAndProps,
  createBasicPageWithBlankLayoutAndProps,
} from 'src/utils/pageCreationUtils';
import { clientTypesKeys } from 'src/enums/clientsTypes';
import { decodeSlugToLanguageCodeAndSlug } from 'src/utils/decodeSlugToLanguageCodeAndSlug';
import { filterCMSContent } from 'src/utils/filterCMSContent';
import { datoCMSQuery } from '../utils/apolloUtils';
import { getArticles } from 'src/utils/getArticles';

const enrichRedirectionDataByLanguage = (redirectData, languageKey) => {
  // NOTE: home page have empty string as url
  if (!redirectData || (!redirectData?.url && redirectData?.url !== '')) {
    return null;
  }

  if (languageKey === defaultLanguage) {
    return redirectData;
  }

  const enrichedData = { ...redirectData };

  // NOTE: inn datoCMS main mage are have empty string
  if (enrichedData.url === '') {
    enrichedData.url = languageKey;
  } else {
    enrichedData.url = `${languageKey}/${enrichedData.url}`;
  }

  return enrichedData;
};

const initialPropsCreator = async ({ locale, apolloClient, isSSR, req, res, asPath, user, ctx }) => {
  let routingMap = null;
  if (req && req.routingMap) {
    routingMap = req.routingMap;
  } else {
    routingMap = await getCachedRoutingMap({ apolloClient });
  }

  const coreRoutesInfo = getCoreRoutesInfoByLocale(routingMap?.coreRoutesInfo, locale);

  const { decodeSlug } = decodeSlugToLanguageCodeAndSlug(asPath);
  const slug = getSlugFromUrl(decodeSlug);

  const isHomePageSlug =
    slug === coreRoutesInfo.homePage.name[locale] ||
    slug === coreRoutesInfo.userB2bHomePage.name[locale] ||
    slug === coreRoutesInfo.userB2cHomePage.name[locale];

  const queryResults = await datoCMSQuery({
    query: GET_PAGE_CONTENT_BY_SLUG,
    client: apolloClient,
    variables: { locale, slug },
    fetchPolicy: isHomePageSlug ? 'no-cache' : 'cache-first',
  }).catch(() => {
    console.debug('> Page info not found in DatoCMS');
    return {};
  });

  const { data: datoCMSPageData } = queryResults;

  if (!datoCMSPageData) {
    return {};
  }

  const { page } = datoCMSPageData;
  const {
    title,
    body,
    bodyTop,
    bodyLeft,
    bodyRight,
    _seoMetaTags,
    redirectForB2bUsers,
    redirectForB2cUsers,
    redirectForNotLoggedInUsers,
    stickLeftColumn,
    stickRightColumn,
    robotsMetaTag,
  } = page;

  const bodyWithArticles = await getArticles(body, locale, user, apolloClient);
  const bodyTopWithArticles = await getArticles(bodyTop, locale, user, apolloClient);
  const bodyRightWithArticles = await getArticles(bodyRight, locale, user, apolloClient);
  const bodyLeftWithArticles = await getArticles(bodyLeft, locale, user, apolloClient);

  const metaTags = robotsMetaTag
    ? [..._seoMetaTags, { tag: 'meta', attributes: { name: 'robots', content: robotsMetaTag } }]
    : [..._seoMetaTags, { tag: 'meta', attributes: { name: 'robots', content: 'index, follow' } }];

  const breadcrumbs = await getBreadcrumbs({ isSSR, req, locale, slug, routingMap });

  const loginPageUrl = coreRoutesInfo.loginPage.name[locale];

  const loginPageQueryResults = await datoCMSQuery({
    query: GET_PAGE_CONTENT_BY_SLUG,
    client: apolloClient,
    variables: { locale, slug: loginPageUrl },
  }).catch(() => {
    console.debug('> Page info not found in DatoCMS');
    return {};
  });

  const { data: loginCMSPageData } = loginPageQueryResults;
  let loginDataFromCMS = {};

  if (loginCMSPageData) {
    const { page: loginPage } = loginCMSPageData;
    loginDataFromCMS = {
      body: loginPage?.body,
      bodyTop: loginPage?.bodyTop,
    };
  }

  if (redirectForB2bUsers || redirectForB2cUsers || redirectForNotLoggedInUsers) {
    redirectUserBasedOnUserTypes(
      {
        [clientTypesKeys.CLIENT_B2B]: enrichRedirectionDataByLanguage(redirectForB2bUsers, locale),
        [clientTypesKeys.CLIENT_B2C]: enrichRedirectionDataByLanguage(redirectForB2cUsers, locale),
        default: enrichRedirectionDataByLanguage(redirectForNotLoggedInUsers, locale),
      },
      { asPath, res, user },
    );
  }

  return {
    title,
    metaTags,
    body: filterCMSContent(bodyWithArticles, { user }, locale),
    bodyTop: filterCMSContent(bodyTopWithArticles, { user }, locale),
    bodyLeft: filterCMSContent(bodyLeftWithArticles, { user }, locale),
    bodyRight: filterCMSContent(bodyRightWithArticles, { user }, locale),
    breadcrumbs,
    coreRoutesInfo,
    loginDataFromCMS,
    stickLeftColumn,
    stickRightColumn,
    routingMap,
  };
};

export const withDatoCMSPageInMainLayout = (PageComponent) =>
  createBasicPageWithMainLayoutAndProps(PageComponent, 'withDatoCMSPage', initialPropsCreator);

export const withDatoCMSPageInBlankLayout = (PageComponent) =>
  createBasicPageWithBlankLayoutAndProps(PageComponent, 'withDatoCMSPage', initialPropsCreator);
